
import { uploadImage } from '@/api/universal/file'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: '封面'
    },
    savedir: {
      type: String,
      default: 'course-series/cover'
    }
  },
  setup: (props, { emit }) => {
    const fileList = ref<any[]>([])
    const toList = () => {
      if (props.value !== '') {
        fileList.value = []
        props.value?.split(',').forEach(c => {
          fileList.value.push({
            url: c
          })
        })
      }
    }
    toList()
    const fileListToStr = () => {
      const infos = ref<any[]>([])
      fileList.value.forEach(c => {
        infos.value.push(c.url)
      })
      emit('update:value', infos.value.toString())
    }
    fileListToStr()
    watch(() => props.value, toList)
    return {
      fileList,
      uploadImage: (file: File) => {
        uploadImage(props.savedir, file).then(r => {
          const attr = props.value ? props.value.split(',') : []
          attr.push(r)
          emit('update:value', attr.toString())
        })
      },
      delCover: () => {
        fileListToStr()
      }
    }
  }
})
