

import { defineComponent, ref, watch } from 'vue'
import { api } from '@/api/useAxios'

export default defineComponent({
  props: {
    courseType: {
      type: Number,
      required: true
    }
  },
  setup: (props, { emit }) => {
    const courseTypeName = ref('')
    const showPicker = ref(false)
    const courseTags = ref<[]>([])
    api.get('/tag/listAllByType/2').then(r => {
      courseTags.value = r
    })
    const choosemode = () => {
      const tagInfo = courseTags.value.find(t => 
        Object(t).id === props.courseType
      )
      if(tagInfo){
        courseTypeName.value = Object(tagInfo).tagName
      }    
    }
    watch(courseTags, choosemode, { deep: true })

    return {
      courseTags,
      courseTypeName,
      showPicker,
      onConfirm: (val:any) => {
        courseTypeName.value = val.tagName;
        emit('update:courseType', val.id)
        showPicker.value = false;
      },
    }
  }
})
