
import MomaiCoverUploaderSpecial from '@/components/MomaiCoverUploaderSpecial.vue'
import { Course } from 'momai'
import { defineComponent, PropType, ref, watch } from 'vue'
import TeacherCheck from './TeacherCheck.vue'
import TeacherHelpCheck from './TeacherHelpCheck.vue'
import TypeSet from './TypeSet.vue'

export default defineComponent({
  components: { MomaiCoverUploaderSpecial, TeacherCheck ,TeacherHelpCheck ,TypeSet },
  props: {
    value: {
      type: Object as PropType<Course>,
      required: true
    }
  },

  setup: (props, { emit }) => {
    const innerFormValue = ref(props.value)

    watch(innerFormValue, newValue => {
      emit('update:course', newValue)
    }, {
      deep: true
    })

    watch(() => props.value, newCourse => {
      innerFormValue.value = newCourse
    }, {
      deep: true
    })

    const showPriceKeyboard = ref(false)
    const showSaleKeyboard = ref(false)

    
    return {
      showPriceKeyboard,
      showSaleKeyboard,
      courseSeriesInfo: innerFormValue
    }
  }
})
